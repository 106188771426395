import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="mamo-device"
export default class extends Controller {
  static targets = ['mamoDisplay'];

  connect() { 
    const mamo_device_operated = $("input[name='lesson[mamo_device_operated]']:checked").val();
    mamo_device_operated === 'true' ? this.mamoDisplayTarget.classList.remove('d-none') : this.mamoDisplayTarget.classList.add('d-none');
  }

  toggleDisplay(e) {
    if (e.target.value === 'true') {
      this.mamoDisplayTarget.classList.remove('d-none');
    } else {
      this.mamoDisplayTarget.classList.add('d-none');
      $("input[name='lesson[mamo_device_cd]']").val([0]);
      $("#lesson_production_year")[0].value = '';
      $("#lesson_device_model_name")[0].value = '';
      $("#lesson_maker")[0].value = '';
    }
  }
}
