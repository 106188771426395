import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tuition"
export default class extends Controller {
  connect() {
    let course_type = $("[name='course[category_cd]']:checked").val();
    this.propagate(course_type);
  }

  propagate(course_type) {
    if (course_type == '1') {
      document.querySelector("#course_tuition").value = 700000
      document.querySelector("#new_edu_fields").style.display = 'block'
      document.querySelector("#refresh_edu_field").style.display = 'none'
    } else {
      document.querySelector("#course_tuition").value = 260000
      document.querySelector("#new_edu_fields").style.display = 'none'
      document.querySelector("#refresh_edu_field").style.display = 'block'
    }
  }

  handleClick(e) {
    const course_type = e.currentTarget.value
    this.propagate(course_type)
  }
}
