import { Controller } from "@hotwired/stimulus";
// Connects to data-controller="payment"
export default class extends Controller {
  static targets = ["ordrIdxx"];

  /****************************************************************/
  /* m_Completepayment  설명                                      */
  /****************************************************************/
  /* 인증완료시 재귀 함수                                         */
  /* 해당 함수명은 절대 변경하면 안됩니다.                        */
  /* 해당 함수의 위치는 payplus.js 보다먼저 선언되어여 합니다.    */
  /* Web 방식의 경우 리턴 값이 form 으로 넘어옴                   */
  /****************************************************************/

  static values = {
    lessonId: Number,
  };
  connect() {
    this.init_orderid();
    const _this = this;

    setTimeout(function () {
      _this.submit_form();
    }, 1000);
  }

  m_Completepayment(FormOrJson, closeEvent) {
    const frm = this.element;

    /********************************************************************/
    /* FormOrJson은 가맹점 임의 활용 금지                               */
    /* frm 값에 FormOrJson 값이 설정 됨 frm 값으로 활용 하셔야 됩니다.  */
    /* FormOrJson 값을 활용 하시려면 기술지원팀으로 문의바랍니다.       */
    /********************************************************************/
    GetField(frm, FormOrJson);

    if (frm.res_cd.value === "0000") {
      alert(
        "결제 승인 요청 전,\n\n반드시 결제창에서 고객님이 결제 인증 완료 후\n\n리턴 받은 ordr_chk 와 업체 측 주문정보를\n\n다시 한번 검증 후 결제 승인 요청하시기 바랍니다."
      ); //업체 연동 시 필수 확인 사항.
      /*
        가맹점 리턴값 처리 영역
      */
      frm.submit();
    } else {
      alert("[" + frm.res_cd.value + "] " + frm.res_msg.value);
      closeEvent();
    }
  }

  init_orderid() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    const time = today.getTime();

    if (month < 10) month = `0${month}`;
    if (date < 10) date = `0${date}`;

    this.ordrIdxxTarget.value = year + "" + month + "" + date + "" + time;
  }

  submit_form() {
    fetch(`/lessons/${this.lessonIdValue}/check_capa.json`)
      .then((res) => res.json())
      .then((json) => {
        if (json.hasOwnProperty("errors") && json.errors.length > 0) {
          console.log(json.errors);
          if (confirm(json.errors.join("\n"))) {
            // pass your url here
            location.href = `/lessons/${this.lessonIdValue}/edit`;
          }
        } else {
          console.log(json.success);
          /* 표준웹 실행 */
          try {
            KCP_Pay_Execute(this.element);
          } catch (e) {
            /* IE 에서 결제 정상종료시 throw로 스크립트 종료 */
          }
        }
      });
  }
}
