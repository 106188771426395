import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = [ "filterNo" ]
  static values = {
    courseId: Number,
  }

  async handleChange(e) {
    $('#spinner').fadeIn();
    console.log(e.currentTarget.value)
    let filter_no = e.currentTarget.value;
    const per_page = $("#items")[0].value;
    const nextURL = `/admin/courses/${this.courseIdValue}?items=${per_page}&filter_no=${filter_no}`;
    const request = new FetchRequest('get', nextURL, { responseKind: 'turbo-stream' })
    const response = await request.perform()
    const nextTitle = 'My new page title';
    const nextState = { additionalInformation: 'Updated the URL with JS' };
    window.history.replaceState(nextState, nextTitle, nextURL);
    console.log(this.filterNoTarget.href);
    this.filterNoTarget.href = `${this.filterNoTarget.href.split("?")[0]}?filter_no=${filter_no}`;
    if (response.ok) {
      $('#spinner').fadeOut();
    }
  }
}
