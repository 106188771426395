import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="certificates"
export default class extends Controller {
  static targets = ['lesson', 'checkAllChecked']
  static values = {
    courseId: Number,
  }

  connect() {
    console.log("Certificates Controller connected...", this.element);
  }

  checkAll(){
    // e.preventDefault()
    const allLessonTargets = this.lessonTargets
    // console.log(this.userAllCheckedTarget.checked)
    allLessonTargets.forEach(lesson => {
      lesson.checked = !lesson.checked
    })
  }

  async issueAll(e) {
    e.preventDefault();

    const courseId = this.courseIdValue
    const lessonChecks = this.lessonTargets.filter(lesson => lesson.checked)
    const ids = lessonChecks.map(lesson => lesson.value).join(',')
    const url = `/admin/courses/${courseId}/create_all_certificates?ids=${ids}`
    if(lessonChecks.length === 0) {
      alert("원하는 항목을 선택한 후 계속하세요.")
    } else {
      if (confirm(`수료증 일괄작성을 계속하시겠습니까?`) === true) {
        // console.log(ids)
        const request = new FetchRequest('post', url, { responseKind: "turbo-stream" })
        const response = await request.perform()
        if (response.ok) {
          // $('#spinner-for-submit').fadeOut('slow');
        }
      }
    }
  }
}
