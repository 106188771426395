import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="open-courses"
export default class extends Controller {
  static values = {
    url: String,
  }

  async handleFilter(e) {
    $("#spinner").fadeIn();
    let filter_on = e.currentTarget.checked ? '1' : '0';
    const request = new FetchRequest('get', `${this.urlValue}?filter_on=${filter_on}`, { responseKind: 'turbo-stream'})
    const response = await request.perform()
    if (response.ok) {
      $("#spinner").fadeOut();
    }
  }
}
