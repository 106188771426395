import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="toast"
export default class extends Controller {
  connect() {
    const option = {
      autohide: false,
    };
    const toast = new bootstrap.Toast(this.element, option)
    toast.show();
  }
}