import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="tos"
export default class extends Controller {
  static targets = ['modal', 'agreement']

  // show_modal() {
  //   this.modal.show();
  // }

  hide_modal() {
    this.modal.hide();
  }
  get modal() {
    return bootstrap.Modal.getOrCreateInstance(this.modalTarget);
  }

  agree(e) {
    e.preventDefault();
    this.agreementTarget.checked = true;
    this.hide_modal();
  }
}
