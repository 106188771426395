export function layout() {
  // handle on page scroll
  var handleHeaderOnScroll = function () {
    if ($(window).scrollTop() > 60) {
      $('body').addClass('page-on-scroll');
    } else {
      $('body').removeClass('page-on-scroll');
    }
  };

  return {
    init: function () {
      // initial setup for fixed header
      handleHeaderOnScroll();
      // handleOnePageHeader(); // initial header

      // handle minimized header on page scroll
      $(window).on('scroll', function () {
        handleHeaderOnScroll();
      });
    },
  };
}
