import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="receipt"
export default class extends Controller {
  static values = {
    tno: String,
    ordrIdxx: String,
  };

  connect() {}

  /* 신용카드 영수증 */
  /* 실결제시 : "https://admin8.kcp.co.kr/assist/bill.BillActionNew.do?cmd=card_bill&tno=" */
  /* 테스트시 : "https://testadmin8.kcp.co.kr/assist/bill.BillActionNew.do?cmd=card_bill&tno=" */
  receiptView(event) {
    event.preventDefault();
    const element = event.currentTarget;
    let receiptWin =
      'https://admin8.kcp.co.kr/assist/bill.BillActionNew.do?cmd=card_bill&tno=';
    // receiptWin += tno + "&";
    // receiptWin += "order_no=" + ordr_idxx + "&";
    // receiptWin += "trade_mony=" + amount ;

    receiptWin += `${this.tnoValue}&order_no=${this.ordrIdxxValue}&trade_mony=${element.dataset.amount}`;

    console.log(receiptWin);
    window.open(receiptWin, '', 'width=455, height=815');
  }

  /* 현금 영수증 */
  /* 실결제시 : "https://admin8.kcp.co.kr/assist/bill.BillActionNew.do" */
  /* 테스트시 : "https://testadmin8.kcp.co.kr/assist/bill.BillActionNew.do" */
  receiptView2(event) {
    event.preventDefault();
    const element = event.target;
    let receiptWin2 =
      'https://admin8.kcp.co.kr/assist/bill.BillActionNew.do?cmd=cash_bill&cash_no=';
    // receiptWin2 += cash_no + "&";
    // receiptWin2 += "order_id="     + ordr_idxx + "&";
    // receiptWin2 += "trade_mony="  + amount ;
    receiptWin2 += `${this.cashNoValue}&order_id=${this.ordrIdxxValue}&trade_mony=${element.dataset.amount}`;

    window.open(receiptWin2, '', 'width=370, height=625');
  }

  /* 가상 계좌 모의입금 페이지 호출 */
  /* 테스트시에만 사용가능 */
  /* 실결제시 해당 스크립트 주석처리 */
  receiptView3(event) {
    event.preventDefault();
    let receiptWin3 =
      'http://devadmin.kcp.co.kr/Modules/Noti/TEST_Vcnt_Noti.jsp';
    window.open(receiptWin3, '', 'width=520, height=300');
  }
}
