// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import './controllers';
import './src/jquery';
import './src/jquery-ui.js';
import '@fortawesome/fontawesome-free/js/all';
import 'trix';
import '@rails/actiontext';
import 'jquery-parallax.js';
import { layout } from './layout';
import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap

// Turbo load (turbolink clicked)
$(document).on('turbo:load', () => {
  console.log('Turbo:load/Turbo:render')
  if ($(".parallax-window")[0]) {
    $('.parallax-window').parallax({
      imageSrc: $('.parallax-window')[0].dataset.imageSrc,
      height: '475px',
    });
    layout().init();
    $(window).trigger('resize').trigger('scroll');
  }

  $('#spinner').fadeOut();
  $('#spinner-for-submit').fadeOut();
});

// turbo:render fires after Turbo renders the page.
$(document).on('turbo:render', function () {
  // console.log('turbo:render event fired...');
  // $('#spinner').fadeOut();
});

// turbo:frame-load fires when <turbo-frame> element is navigated and finishes loading (fires after turbo:frame-render)
$(document).on('turbo:frame-load', function () {
  // console.log('turbo:frame-load event fired...');
  $('#spinner').fadeOut();
});

// turbo:frame-render fires right after <turbo-frame> element renders its view.
$(document).on('turbo:frame-render', function () {
  // console.log('turbo:frame-render event fired...');
  $('#spinner').fadeOut();
});

// turbo:submit-start fires during a form submission.
$(document).on('turbo:submit-start', function () {
  console.log('forced to fade out #spinner before turbo:submit-start event to be fired...');
  $('#spinner').fadeOut();
  // console.log('turbo:submit-start event fired...');
  $('#spinner-for-submit').fadeIn();
});

// turbo:submit-end fires after the form submission-initiated network request completes.
$(document).on('turbo:submit-end', function () {
  console.log('turbo:submit-end event fired...');
  $('#spinner-for-submit').fadeOut();
});

document.addEventListener('scroll', (e) => {
  if (document.querySelector('.header')) {
    const scrollTop = e.target.scrollingElement.scrollTop;
    if (scrollTop > 0) {
      document.querySelector('.header').classList.add('bg-white', 'border-bottom');
    } else {
      document.querySelector('.header').classList.remove('bg-white', 'border-bottom');
    }
  }
})