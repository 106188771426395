import { Controller } from "@hotwired/stimulus"
import $fp from "lodash/fp";

// Connects to data-controller="kakao-postcode"
export default class extends Controller {
  static targets = ["detail"]

  connect() {
    const _this = this;
    document.getElementById('postcodeModal').addEventListener('show.bs.modal', this.onModalShow);
    document.getElementById('postcodeModal').addEventListener('hidden.bs.modal', function(){
      setTimeout(function () {
        if (_this.hasDetailTarget) {
          _this.detailTarget.focus();
        } else {
          console.log("Detail target element not existed.");
        }
      }, 100);
    });
  }

  onModalShow(e){
    const $modal = $($fp.get("target", e));
    const $modalBody = $modal.find(".modal-body");

    const getDeviceWidth = () => {
      return window.innerWidth || document.documentElement.clientWidth;
    };

    const initializeModal = $fp.curry(($modalBody, deviceWidth) => {
      const modalBodyMaxWidth = 500;
      const modalXMargin = 8;
      const borderSize = 1; // border 크기

      const width =
        deviceWidth >= modalBodyMaxWidth
          ? modalBodyMaxWidth - borderSize
          : deviceWidth - modalXMargin * 2 - borderSize * 2;
      const height =
        deviceWidth >= modalBodyMaxWidth
          ? 444 // daum postcode pc 기본높이
          : 466; // daum postcode mobile 기본 높이

      $modalBody.css({
        width: `${width}px`,
        height: `${height}px`, // resize 반환값의 기본값
        "max-width": `${width + borderSize + modalXMargin}px`,
        overflow: "hidden",
      });
    });

    const onComplete = (data) => {
      document.querySelector("#lesson_zip_code, #profile_workplace_zip_code").value = data.zonecode;
      document.querySelector("#lesson_delivery_addr, #profile_workplace_addr").value = `${data.address} ${data.buildingName} `;
      // $("#postcodeModal").modal("hide");
      let genericModalEl = document.getElementById('postcodeModal');
      let modal = bootstrap.Modal.getInstance(genericModalEl);
      modal.hide();
    };

    const onResize = $fp.curry(($modalBody, borderSize, size) => {
      $modalBody.css({
        height: `${$fp.get("height", size) + borderSize * 2 + 30}`,
      });
    })($modalBody, 1);

    new daum.Postcode({
      oncomplete: onComplete,
      onresize: onResize,
      width: "100%",
      height: "100%",
    }).embed($modalBody.get(0));

    const composer = $fp.pipe(getDeviceWidth, initializeModal($modalBody));

    composer();
  };
}
