import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="capa"
export default class extends Controller {
  connect() {
    // console.log('Capa controller connected...', this.element)
  }

  handleClick(e) {
    // console.log(e.currentTarget.value)
    // console.log(this.element)
  }
}
