import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pay-method"
export default class extends Controller {
  static values = {
    lessonId: Number,
    payKey: String,
  }

  connect() { 
    this.toggle(this.payKeyValue)
  }

  payKeyValueChanged() {
    this.toggle(this.payKeyValue)
    // console.log(document.querySelector('form').action)
  }

  connect() {
    // console.log(this.element)
    // this.keyValue = document.querySelector("input[name='pay_method']:checked").value
  }

  handleClick(e){
    // console.log(e.currentTarget.value)
    this.payKeyValue = document.querySelector("input[name='pay_method']:checked").value
  }

  toggle(value){
    if(value === 'x10000000000'){
      document.querySelector('#pay-with-bank-account').style.display = 'block'
      document.querySelector('#check_date').focus();
      document.querySelector('form').action = `/lessons/${this.lessonIdValue}/payment/create_with_bank_account`
      document.querySelector('form').dataset.controller = ''
      document.querySelector('#btn_submit').dataset.action = ''
      document.querySelector('#btn_submit').setAttribute('type', 'submit');
    } else {
      document.querySelector('#pay-with-bank-account').style.display = 'none'
      document.querySelector('form').action = `/lessons/${this.lessonIdValue}/payment/create_with_card`
      document.querySelector('form').dataset.controller = 'payment'
      document.querySelector('#btn_submit').dataset.action = 'click->payment#submit_form'
      document.querySelector('#btn_submit').setAttribute('type', 'button');
    }
  }
}
