import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  initialize() {
    super.initialize();
    $('.modal').on('shown.bs.modal', function(e) {
      $(this).find('[autofocus]:first').focus();
    });
  }

  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false
    })
    this.modal.show()
  }

  disconnect() {
    this.modal.hide()
  }
}
