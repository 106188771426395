import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-duplicate"
export default class extends Controller {
  static targets = ["searchQ", "checkButton", "checkResult", "checkBtn"]
  static values = {
    knownKeyNo: String,
    searchKey: String,
  }

  connect() {
    // console.log(this.element)
    if (this.searchQTarget.value.length === 0) {
      this.checkButtonTarget.style.display = 'none'
    } else {
      this.checkButtonTarget.style.display = 'inline-block'
    }
  }

  handleDuplicate(e) {
    if (e.target.checked) {
      fetch(`/profiles/search.json?key=${this.searchKeyValue}&q=${this.searchQTarget.value}`)
        .then(response => response.json())
        .then(profiles => {
          const search_keys = profiles.map(profile => profile[this.searchKeyValue])
          if (profiles.length > 0) {
            if (search_keys.includes(this.knownKeyNoValue)) {
              this.checkResultTarget.innerHTML = "<i class='fa fa-arrow-right text-primary'></i> <i class='fa fa-user text-primary'></i> 사용가능(현재상태)"
            } else {
              this.checkResultTarget.innerHTML = "<i class='fa fa-arrow-right text-danger'></i> 사용불가 <i class='fa fa-times text-danger'></i>"
            }
          } else {
            this.checkResultTarget.innerHTML = "<i class='fa fa-arrow-right text-success'></i> 사용가능 <i class='fa fa-check text-success'></i>"
          }
        })
    } else {
      this.checkResultTarget.innerHTML = ''
    }
  }

  handleKeyChange(e) {
    this.checkBtnTarget.checked = false
    this.handleDuplicate(e)
  }

  toggleCheckButton(e) {
    const chars = e.target.value
    if (chars.length === 0) {
      this.checkButtonTarget.style.display = 'none'
    } else {
      this.checkButtonTarget.style.display = 'inline-block'
    }
  }

}
