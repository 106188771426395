import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="pagy"
export default class extends Controller {
  static values = { id: String }

  connect() {
    // console.log(this.element)
    this.element.setAttribute("data-action", "change->pagy#changeItems")
  }

  async changeItems(e) {
    $('#spinner').fadeIn();
    console.log(e.currentTarget.value)
    let per_page = e.currentTarget.value;
    const filter_no = $("#filter_id")[0].value;
    const nextURL = `/admin/courses/${this.idValue}?items=${per_page}&filter_no=${filter_no}`
    const request = new FetchRequest('get', nextURL, { responseKind: 'turbo-stream' })
    const response = await request.perform()
    const nextTitle = 'My new page title';
    const nextState = { additionalInformation: 'Updated the URL with JS' };
    window.history.replaceState(nextState, nextTitle, nextURL);
    if (response.ok) {
      $('#spinner').fadeOut();
    }
  }
}
